<template>
    <!-- <div class="relative w-[19.875rem]  cursor-pointer ring-blue-100  rounded-lg bg-white text-[0.8125rem] leading-5 text-slate-900 shadow-xl shadow-black/5 ring  hover:ring-blue-500" >
    <font-awesome-icon icon="pencil" />
    <button v-if="edit==true" class="bg-blue-700 text-white-text text-sm px-2 py-2 absolute rounded-lg bottom-2 right-2" @click="editPackage(tenant_package.id)">configure </button>
    <button v-else class="bg-blue-700 text-white-text text-sm px-2 py-2 absolute rounded-lg bottom-2 right-2" @click="openCaseCreateForm(tenant_package.id)">create case </button>

    <div class="flex items-center p-4 pb-0 ">
        <div class="ml-4 flex-auto relative">
            <div class="text-[0.8125rem] font-semibold leading-5 text-black " >{{tenant_package.package_name}}</div>
            <div v-if="tenant_package.mark_as_default==true" class="absolute text-xs text-gray-600 top-0 right-0 bg-purple-200 py-1 px-2 rounded-md">default</div>
            <div class="mt-1 text-black text-sm">{{tenant_package.package_description}}</div>
        </div>
    </div>
    <div class="flex gap-3 p-4">
        <div v-if="tenant_package.turn_around_time>=1" class="pointer-events-auto rounded-md py-2 px-3 ">
            {{tenant_package.turn_around_time}} days
        </div>
        <div v-else class="pointer-events-auto rounded-md py-2 px-3 text-xs text-gray-500">
              No SLA set for this package
        </div>
    </div>
  </div> -->

    <div class="card bg-white shadow-sm">
        <div class="card-body p-6 text-base-content">
            <div class="flex items-center justify-between">
                <h2 class="card-title text-base line-clammp-1">{{ tenant_package.package_name || "No title" }}</h2>
                <span v-if="$route.name === 'Screening Package'" @click.stop="showCloneModal(tenant_package)" title="Clone package" class="h-8 items-center justify-center flex rounded-half w-8 bg-gray-200 hover:bg-gray-300">
                    <font-awesome-icon icon="clone" class="text-base-content fill-current ease-in-out transition-all duration-100 w-24 text-md self-center cursor-pointer" />
                </span>
            </div>
            <p class="text-sm mb-2 flex-grow line-clamp-3">{{ tenant_package.package_description }}</p>
            <span class="badge badge-info" v-if="tenant_package.automated">Automated</span>

            <div class="card-actions mt-auto flex-nowrap gap-4 items-center justify-between">
                <p class="text-xs text-base-content-300 m-0 pl-1 items-center">
                    {{ tenant_package.turn_around_time >= 1 ? tenant_package.turn_around_time + " days" : "No SLA set for this package" }}
                </p>
                <button v-if="edit == true" class="btn btn-sm rounded-xl normal-case btn-outline btn-primary" @click="editPackage(tenant_package.id)">Configure</button>
                <button @click="caseCreate(tenant_package)" v-else class="btn btn-sm rounded-xl no-animation normal-case btn btn-primary">
                    <Loader :size="4" class="mr-2" v-if="tenant_package.isLoading" />
                    Create Case
                </button>
            </div>
        </div>
    </div>
</template>
<script>
const Loader = () => import("@/components/loader");

import {createNeotasCase} from "@shared/light-ray/pages/search-records/services"
import VueCookies from "vue-cookies";


export default {
    name: "package-card",
    components: { Loader },
    props: ["tenant_package", "progress", "edit"],
    data() {
        return {};
    },
    methods: {
        editPackage(package_id) {
            this.$router.push({
                name: "Screening Package View",
                params: { packageName: package_id },
            });
        },
        openCaseCreateForm() {
            //this.$router.push({
            //name: "create-case-form",
            //params: { id: package_id },
            //});
        },
        async caseCreate(tenant_package) {
            if (tenant_package?.automated) {
                const payload = {
                    due_diligence_level: process.env.VUE_APP_NEOTAS_PRODUCT_NAME,
                    self_service: true
                }
                const {data} = await createNeotasCase(payload)
                VueCookies.set("neotas_case", data.case_id)
                this.$store.dispatch("setNeotasCaseId", data.case_id)
                this.$router.push({name:"search-company-records",
                params:{neotasCase: data.case_id ,type: "company-search"}, query: {package_id:tenant_package.id, client_id: this.$route.query.client}})
            }
            else {
                this.$emit("create", tenant_package);
            }
        },
        showCloneModal(tenant_package) {
            this.$emit("showCloneModal", tenant_package);
        },
    },
};
</script>
<style scoped lang="scss">
.loader {
    border-color: #ffffff !important;
    // border-bottom-color: #0d69d5 !important;
    border-bottom-color: var(--theme-color-primary-300) !important;
    border-width: 3px !important;
}
</style>
